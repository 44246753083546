<template>
  <div class="wrap">
    <div class="banner">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">企业动态</p>
      </div>
    </div>
    <div class="content bg">
      <div class="news">
        <ul class="news-list">
          <li class="item">
            <router-link to="/news/new_04" target="_blank">
              <p class="img"><img src="../../assets/images/news/new4-img6.png" alt="" style="margin-top: -75px"></p>
              <p class="text">适应全民健康管理服务的新需求，实现由“被动医疗”向“主动健康”服务模式理念转型升级的全新实践，中部战区总医院干部病房三科（综合科）应运而生。科室拥有我公司先进的CDR+数字病区系统，数字化、智能化、自动化医疗及护理服务平台。病房实现了对病人各项生理指标、诊疗状态等进行集中监控及预警...<span>[2020-09-04]</span></p>
              <p class="more"><span>查看详情 ></span></p>
            </router-link>
          </li>
          <li class="item">
            <router-link to="/news/new_03" target="_blank">
              <p class="img"><img src="../../assets/images/news/new3-img1.jpg" alt="" style="margin-top: -45px"></p>
              <p class="text">7月10日上午，2020武汉互联网突围大咖直播访谈第六期“打破常规 创造奇迹”准时开讲，武汉软件新城发展有限公司总经理梁勇，武汉必盈生物科技有限公司总经理刘朋，英特沃德（武汉）医疗科技发展有限公司创始人邢晓幕，共同探讨如何用技术力量来打破固有格局，让互联网医疗真正成为老百姓触手可及的民生福利...<span>[2020-07-10]</span></p>
              <p class="more"><span>查看详情 ></span></p>
            </router-link>
          </li>
          <li class="item">
            <router-link to="/news/new_02" target="_blank">
              <p class="img"><img src="../../assets/images/news/new2-img2.png" alt="" style="margin-top: -120px"></p>
              <p class="text">“新冠抗疫”期间英特沃德联手兄弟公司捐赠了价值120万元的制氧机，并在疫情严重时期请不到搬运工的情形下，与兄弟公司共同承担了部分一线医院的制氧保障职能，主要是安装、维护制氧设备。4月份周先旺市长来软件新城考察，园区数百家企业中，为疫情提供支持的上傍企业中，我公司名列其一...<span>[2020-04-10]</span></p>
              <p class="more"><span>查看详情 ></span></p>
            </router-link>
          </li>
          <li class="item">
            <router-link to="/news/new_01" target="_blank">
              <p class="img"><img src="../../assets/images/news/new1-img1.png" alt="" style="margin-top: -45px"></p>
              <p class="text">武汉军运会，我公司为军运会重点保障医院“中部战区总医院”特辟的“军运会住院专区”提供了自行研发生产的CDR+数字病区全套软硬件系统，去年“十一”前交付，军运会期间病区接收了多个国家的数十名受伤教练员、运动员入住，先进的数字化装备，为医院为中国增添了光彩，受到医院领导、政府领导的好评...<span>[2019-10-23]</span></p>
              <p class="more"><span>查看详情 ></span></p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'News',
  metaInfo: {
    title: '英特沃德（武汉）医疗科技发展有限公司-企业动态',
    meta: [{
      name: '医疗系统开发，医疗大数据应用，软件系统研发与集成，医疗装备研发生产与集成，数字病区',
      content: '英特沃德（武汉）医疗高科，是集医疗系统开发、医疗大数据应用、软件系统研发与集成、医疗装备研发生产与集成、投资、销售与运营服务为一体的高科技企业，项目坐落在中国光谷。'
    }],
    link: [{
      rel: '英特沃德（武汉）医疗科技发展有限公司',
      href: 'http://www.ytwdwh.com/'
    }]
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url("../../assets/images/news-banner.png") no-repeat center;
  text-align: center;
  overflow: hidden;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: #fff url("../../assets/images/banner5-icon.png") no-repeat center;
    border-radius: 50%;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.bg {
  background: #fafafa;
}
.content {
  position: relative;
  padding: 60px 0;
  .news {
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    .news-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .item {
        width: 46%;
        background: #fff;
        a {
          color: #434343;
        }
        .img {
          // border-radius: 10px;
          width: 100%;
          height: 245px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            // height: 100%;
          }
        }
        .text {
          padding: 15px 10px;
          line-height: 30px;
          font-size: 16px;
          text-indent: 2em;
          span {
            float: right;
          }
        }
        .more {
          text-align: center;
          margin-bottom: 25px;
          span {
            padding: 5px 10px;
            background: #fafafa;
            font-size: 16px;
            color: #00dcd4;
          }
        }
      }
    }
  }
}
</style>
